export const sources = [
    {
      'lang': 'en',
      'text': 'English',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm' },
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm' },
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'fr', 'text': 'French', 'service': 'llm' },
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm' },
        {'lang': 'de', 'text': 'German', 'service': 'llm' },
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm' },
        {'lang': 'it', 'text': 'Italian', 'service': 'llm' },
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm' },
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm' },
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm' },
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'pt-br',
      'text': 'Brazilian Portuguese',
      'target': [
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'ca',
      'text': 'Catalan',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'zh',
      'text': 'Chinese',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'hr',
      'text': 'Croatian',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'fr',
      'text': 'French',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'ga',
      'text': 'Galician',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'de',
      'text': 'German',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'id',
      'text': 'Indonesian',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'it',
      'text': 'Italian',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'ja',
      'text': 'Japanese',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'kr',
      'text': 'Korean',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'ru',
      'text': 'Russian',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm'}, 
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm'}, 
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm'}, 
        {'lang': 'fr', 'text': 'French', 'service': 'llm'}, 
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm'}, 
        {'lang': 'de', 'text': 'German', 'service': 'llm'}, 
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm'}, 
        {'lang': 'it', 'text': 'Italian', 'service': 'llm'}, 
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm'}, 
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm'}, 
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'es',
      'text': 'Spanish',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm' },
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm' },
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm' },
        {'lang': 'fr', 'text': 'French', 'service': 'llm' },
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm' },
        {'lang': 'de', 'text': 'German', 'service': 'llm' },
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm' },
        {'lang': 'it', 'text': 'Italian', 'service': 'llm' },
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm' },
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm' },
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm' },
        {'lang': 'ua', 'text': 'Ukrainian', 'service': 'llm'}
      ]
    },
    {
      'lang': 'ua',
      'text': 'Ukrainian',
      'target': [
        {'lang': 'pt-br', 'text': 'Brazilian Portuguese', 'service': 'llm' },
        {'lang': 'ca', 'text': 'Catalan', 'service': 'llm' },
        {'lang': 'zh', 'text': 'Chinese', 'service': 'llm' },
        {'lang': 'hr', 'text': 'Croatian', 'service': 'llm' },
        {'lang': 'en', 'text': 'English', 'service': 'llm' },
        {'lang': 'fr', 'text': 'French', 'service': 'llm' },
        {'lang': 'ga', 'text': 'Galician', 'service': 'llm' },
        {'lang': 'de', 'text': 'German', 'service': 'llm' },
        {'lang': 'id', 'text': 'Indonesian', 'service': 'llm' },
        {'lang': 'it', 'text': 'Italian', 'service': 'llm' },
        {'lang': 'ja', 'text': 'Japanese', 'service': 'llm' },
        {'lang': 'kr', 'text': 'Korean', 'service': 'llm' },
        {'lang': 'ru', 'text': 'Russian', 'service': 'llm' },
        {'lang': 'es', 'text': 'Spanish', 'service': 'llm'},
      ]
    },
  ];