import React, { useState, useContext } from 'react';
import './dropdown.css';
import FileUpload from './uploadFile';
import { MyContext } from '../../Context/translateContext';
import { Dropdown } from './dropdown';
import { CreateProfile, TranslateMT , PostEdit, Feedback, PostHumanEdition} from './core/request';


function TranslatorPanel({ type }) {
  const [loading, setLoading] = useState(false);
  const [showButtonTranslate, setShowButtonTranslate] = useState(false);
  const [profileId, setProfileId] = useState("");

  const [likeOption, setLikeOption] = useState(null);
  const [disabledFeedback, setDisabledFeedback] = useState(false);
  const [disabledHumanEdition, setDisabledHumanEdition] = useState(false);

  const { 
    text1, setText1,
    text2, setText2,
    text3, setText3,
    clientProfile, setClientProfile,
    optionsSource, // setOptionsSource,
    optionsTarget, // setOptionsTarget,
    selectedSource, setSelectedSource,
    selectedTarget, setSelectedTarget,
    selectedService,  
    langSource, langTarget,
    showAnswerPE, setShowAnswerPE,
    showFeedback, setShowFeedback,
    selectedFile
  } = useContext(MyContext);

  const handleTextChange1 = (e) => {
    if (e.target.value.length <= 1500) setText1(e.target.value);
    setShowButtonTranslate(e.target.value.length > 0);
  };

  const handleFeedback = async (like) => {
    if(profileId){
      await Feedback(profileId, like)
      setLikeOption(like === 1 ? 'like' : 'dislike');
      setDisabledFeedback(true)
    }
  };

  const handleHumanEdition = async () => {
    if(profileId)
    {
      await PostHumanEdition(profileId, text2)
      setDisabledHumanEdition(true)
    } 
  };

  const handleTextChange2 = (e) => {
    setText2(e.target.value);
    if(e.target.value === "") {
       setShowAnswerPE(false)
       setDisabledHumanEdition(false)
    }
    else setShowAnswerPE(true) 
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Copied to clipboard (clipboard):', text);
      }).catch(err => {
        console.error('Error al copiar al portapapeles:', err);
      });
    }
    else{
      const textarea2 = document.getElementById("target")
      textarea2.select()
      try {
        document.execCommand('copy');
        console.log('Copied to clipboard (cmd):', text);
      } catch (err) {
        console.error('Error usando fallback para copiar:', err);
      }
    }
  };

  const handleTranslateClick = async () => {
    setDisabledHumanEdition(false)
    setShowAnswerPE(false)
    setLikeOption(null)
    setDisabledFeedback(false)
    setText3("")

    if(text1 && langSource && langTarget)
    {
      if(type === "MT")
      {
        setLoading(true);
        const target = await TranslateMT(text1, langSource, langTarget)
        setText2(target)
        setLoading(false);
      }
      else
      {
        setLoading(true);

        let CP = clientProfile
        if(!clientProfile || clientProfile.sources !== langSource || clientProfile.target !== langTarget) {
          CP =  await CreateProfile(langSource, langTarget)
          setClientProfile({id: CP.id, sources: CP.sources, target: CP.target})  
        } 
          
        const target = await TranslateMT(text1, langSource, langTarget, selectedService)

        let references = "No references found."
        
        if(target && selectedFile !== null)
        {
          const postEdit = await PostEdit(CP.id, text1, target)

          if(postEdit.alignments[0].tgt)
          {
            const tgt = postEdit.alignments[0].tgt
            const ArrayReferences = postEdit.alignments[0].references
            
            if(ArrayReferences)
            {
              references = ""
              for (const element of ArrayReferences) {
                references += `- ${element.src}\n`;
                references += `- ${element.tgt}\n\n`;
              }
            }

            setProfileId(postEdit.alignments[0].id)
            setText2(tgt)
            setText3(references)
            setShowFeedback(true)
          }
        }
        else
        {
          setText2(target)
          setText3("Not file to reference")
        }

        setLoading(false);
      }
    }
    else  console.log("Empty fields", text1, langSource, langTarget)
    
  };

  return (
    <>
      <div className="relative flex flex-col rounded-t-md container max-w-7xl mx-auto shadow-md border-b-pangeamt-green text-pangeamt-bluedark">
        <div className="rounded-t-md w-full flex flex-col">
          <div className="flex items-center bg-white py-2">
            <div className="flex items-center flex-1">
              <div className="ml-3">
                <div className="relative">
                  <Dropdown
                    type="source"
                    options={optionsSource}
                    selectedOption={selectedSource}
                    setSelectedOption={setSelectedSource}
                  />
                </div>
                <div className="fixed h-screen w-full top-0 left-0 hidden"></div>
              </div>
            </div>
            {/* <button className="hover:text-pangeamt-orange">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="fill-current">
                <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
            </button> */}
            <div className="flex items-center flex-1">
              <div className="ml-3">
                <div className="relative">
                  <Dropdown
                    type="target"
                    options={optionsTarget}
                    selectedOption={selectedTarget}
                    setSelectedOption={setSelectedTarget} 
                  />
                </div>
                <div className="fixed h-screen w-full top-0 left-0 hidden"></div>
              </div>
            </div>
          </div>
          <div className="grid grid-col-1 md:grid-cols-2 items-center">
            <div className="flex-1 flex flex-col">
              <div className="border-r border-slate-300 p-4 bg-gray-100 rounded-tl-md">
                <div className="min-h-[40px]">
                  <div className="flex flex-col">
                    
                      <div className="flex justify-between items-center h-8 pb-4">
                        <span className="text-xl font-semibold text-pangeamt-bluedark pl-3"></span>
                        {showButtonTranslate && (
                        <button
                          className="text-gray-500 hover:text-gray-700"
                          onClick={() => setText1("")} // Limpiar el texto
                        >
                              <svg height="1.5em" width="1.5em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill="currentColor"
                                  d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
                                />
                              </svg>
                        </button>
                          )}
                      </div>
                  

                    <textarea
                      id='source'
                      placeholder="Type to translate..."
                      rows="6"
                      spellCheck="false"
                      maxLength="1500"
                      className="resize-none w-full focus:outline-none text-base lg:text-xl text-slate-500 custom-scrollbar bg-gray-100 px-3"
                      value={text1}
                      onChange={handleTextChange1}
                    >
                    </textarea>
                  </div>
                </div>
                <div className="h-10">
                  <div className="flex flex-grow items-center">
                    <div className="flex-none">
                      <div className="text-sm text-gray-400">
                        {text1.length}/1500
                      </div>
                    </div>

                    {showButtonTranslate && (
                      <div className="grow">
                        <div className="flex float-right">
                          <div className="text-center border rounded-md cursor-pointer transition-opacity duration-100 text-base py-2 px-5 min-w-[80px] font-medium hover:text-pangeamt-orange text-pangeamt-bluedark border-transparent">
                            <button 
                              disabled={loading} 
                              onClick={handleTranslateClick}
                              className="flex items-center gap-2">
                              {loading ? 'Translating....' : (
                                <>
                                  Translate
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                  </svg>
                                </>
                              )}

                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 flex flex-col">
              <div className="border-t border-gray-200 p-4 bg-gray-100 rounded-tr-md">
                <div className="min-h-[40px]">
                  <div className="flex flex-col">

                      <div className="flex justify-between items-center h-8 pb-4">
                        <span className="text-xl font-semibold text-pangeamt-bluedark pl-3"></span>
                        {text2 && (
                          <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => copyToClipboard(text2)} // Limpiar el texto
                          >
                              <svg height="1.5em" width="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 16.5L19.5 4.5L18.75 3.75H9L8.25 4.5L8.25 7.5L5.25 7.5L4.5 8.25V20.25L5.25 21H15L15.75 20.25V17.25H18.75L19.5 16.5ZM15.75 15.75L15.75 8.25L15 7.5L9.75 7.5V5.25L18 5.25V15.75H15.75ZM6 9L14.25 9L14.25 19.5L6 19.5L6 9Z" fill="#080341"/>
                              </svg>
                          </button>
                          )}
                      </div>

                    <textarea
                      id='target'
                      rows="6"
                      // disabled="disabled"
                      spellCheck="false"
                      maxLength="1500"
                      value={text2}
                      className="resize-none w-full focus:outline-none text-base lg:text-xl custom-scrollbar bg-gray-100 pr-3 pl-4"
                      onChange={handleTextChange2}
                    >
                    </textarea>
                  </div>
                </div>
                <div className="h-10">
                    <div className="flex flex-grow items-center">

                      {showAnswerPE && (
                        <div className="flex-none">
                          <div className="flex items-center gap-2">
                            <span>Have you improved the translation?</span>
                            <button 
                                className={`flex items-center ${disabledHumanEdition ? 'opacity-50' : ''}`}
                                disabled={disabledHumanEdition}
                                onClick={handleHumanEdition}
                                style={{ border: '1px solid transparent', padding: '5px'}}
                              >
                                <svg height="1.5em" width="1.5em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15.9957 11.5C14.8197 10.912 11.9957 9 10.4957 9C8.9957 9 5.17825 11.7674 6 13C7 14.5 9.15134 11.7256 10.4957 12C11.8401 12.2744 13 13.5 13 14.5C13 15.5 11.8401 16.939 10.4957 16.5C9.15134 16.061 8.58665 14.3415 7.4957 14C6.21272 13.5984 5.05843 14.6168 5.5 15.5C5.94157 16.3832 7.10688 17.6006 8.4957 19C9.74229 20.2561 11.9957 21.5 14.9957 20C17.9957 18.5 18.5 16.2498 18.5 13C18.5 11.5 13.7332 5.36875 11.9957 4.5C10.9957 4 10 5 10.9957 6.5C11.614 7.43149 13.5 9.27705 14 10.3751M15.5 8C15.5 8 15.3707 7.5 14.9957 6C14.4957 4 15.9957 3.5 16.4957 4.5C17.1281 5.76491 18.2872 10.9147 18.4957 13" stroke="#121923" stroke-width="1.2"/>
                                </svg>
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="grow pt-3">
                        <div className="flex float-right gap-4">

                          {showFeedback && (
                            <>
                              <button 
                                  disabled={disabledFeedback} 
                                  className={`flex items-center hover:text-orange ${likeOption === 'dislike' ? 'opacity-50' : ''}`}
                                  onClick={() => handleFeedback(1)}
                                >
                                <svg viewBox="0 0 1024 1024" fill="currentColor" height="1.5em" width="1.5em">
                                  <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 00-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 00471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0142.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z" />
                                </svg>
                              </button>
                              <button 
                                  disabled={disabledFeedback}
                                  className={`flex items-center hover:text-orange ${likeOption === 'like' ? 'opacity-50' : ''}`}
                                  onClick={() => handleFeedback(-1)}
                                  >
                                <svg viewBox="0 0 24 24" fill="currentColor" height="1.5em" width="1.5em" >
                                  <path d="M20 3H6.693A2.01 2.01 0 004.82 4.298l-2.757 7.351A1 1 0 002 12v2c0 1.103.897 2 2 2h5.612L8.49 19.367a2.004 2.004 0 00.274 1.802c.376.52.982.831 1.624.831H12c.297 0 .578-.132.769-.36l4.7-5.64H20c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-8.469 17h-1.145l1.562-4.684A1 1 0 0011 14H4v-1.819L6.693 5H16v9.638L11.531 20zM18 14V5h2l.001 9H18z" />
                                </svg>
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>


          {type === "AutoPE" && (               
            <div className="grid grid-col-1 items-center">
              <div className="w-full flex flex-col">
                <div className="border-t border-gray-200 p-4 bg-gray-100 rounded-tr-md">
                  <div className="min-h-[40px]">
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center h-8 pb-4">
                        <span className="text-xl font-semibold text-pangeamt-bluedark pl-4"></span>
                      </div>
                      <textarea
                        rows="3"
                        disabled="disabled"
                        spellCheck="false"
                        // maxLength="1500"
                        value={text3}
                        className="resize-none w-full focus:outline-none text-base text-sm custom-scrollbar bg-gray-100 pr-3 pl-4"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div className="flex float-right gap-4">
                    <p className='text-sm text-gray-400'>Retrieved reference</p>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>

      {type === "AutoPE" && <FileUpload />}

    </>
  );
}

export default TranslatorPanel;
