import React, { createContext, useState, useEffect } from 'react';
import {sources} from './languaje'

// Crear el contexto
export const MyContext = createContext();

// Crear el proveedor de contexto
export const MyProvider = ({ children }) => {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('')
  const [clientProfile, setClientProfile] = useState(null);
  const [optionsSource, setOptionsSource] = useState(sources);
  const [optionsTarget, setOptionsTarget] = useState(sources[0].target);
  const [selectedSource, setSelectedSource] = useState(sources[0].text);
  const [selectedTarget, setSelectedTarget] = useState(optionsTarget[0].text);
  const [selectedService, setSelectedService] = useState(optionsTarget[0].service);
  const [langSource, setLangSource] = useState(sources[0].lang);
  const [langTarget, setLangTarget] = useState(optionsTarget[0].lang);
  const [showAnswerPE, setShowAnswerPE] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const selectedLang = sources.find(source => source.text === selectedSource);
    setOptionsTarget(selectedLang.target);
    setLangSource(selectedLang.lang)
  }, [selectedSource]);

  return (
    <MyContext.Provider value={{ 
        text1, setText1,
        text2, setText2,
        text3, setText3,
        clientProfile, setClientProfile,
        optionsSource, setOptionsSource,
        optionsTarget, setOptionsTarget,
        selectedSource, setSelectedSource,
        selectedTarget, setSelectedTarget,
        selectedService, setSelectedService,
        langSource, setLangSource,
        langTarget, setLangTarget,
        showAnswerPE, setShowAnswerPE,
        showFeedback, setShowFeedback,
        selectedFile, setSelectedFile
        }}>
      {children}
    </MyContext.Provider>
  );
};
