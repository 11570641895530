

function generateRandomString(length = 20) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
}

const CreateProfile = async (langSource, langTarget) => {
    const ProfileId = generateRandomString();
    let ClientProfile = null

    try {
        const response = await fetch("/createProfile", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "id": ProfileId,
                "src": langSource,
                "tgt": langTarget,
            }),
        });

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error en la solicitud: ${response.statusText} - ${errorDetails}`);
        }

        ClientProfile = {
            id: ProfileId,
            sources: langSource,
            target: langTarget
        }
    } catch (error) {
        console.error(error);
    }

    return ClientProfile; // or some JSX if needed
};

const PostEdit = async (ProfileId, langSource, langTarget) => {

    try {
        const response = await fetch("/postedit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "id": ProfileId,
                "src": langSource,
                "tgt": langTarget,
            }),
        });

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error en la solicitud: ${response.statusText} - ${errorDetails}`);
        }

        const result = await response.json();
        return result
    } catch (error) {
        console.error(error);
        return error; 
    }
};


const TranslateMT = async (text1, langSource, langTarget, service) =>{
    try {
        const response = await fetch("/translate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "txt": text1,
                "src": langSource,
                "tgt": langTarget,
                "srv": service
            }),
        });

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error en la solicitud: ${response.statusText} - ${errorDetails}`);
        }
  
        const result = await response.json();
        let transition = "Error in translation"

        if(!result.error) 
        {
            if (service === "llm" && result.translation_list[0]) transition = result.translation_list[0]
            if (service === "p24" && result.tus[0].tgt) transition = result.tus[0].tgt;
        }

        return transition 
      } catch (error) {
          console.error('Error:', error);
          return 'Error:'+ error
      }
}

const Feedback = async (profileId, feedback) =>{
    try {
        const response = await fetch("/feedback", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "id": profileId,
                "feedback": feedback
            }),
        });
  
        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error en la solicitud: ${response.statusText} - ${errorDetails}`);
        }
  
        const result = await response.json();
        return result;
      } catch (error) {
          console.error('Error:', error);
          return 'Error:'+ error
      }
}

const PostHumanEdition = async (profileId, tgt) =>{
    try {
        const response = await fetch("/post_human_edition", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "id": profileId,
                "tgt": tgt
            }),
        });
  
        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error en la solicitud: ${response.statusText} - ${errorDetails}`);
        }
  
        const result = await response.json();
        return result;
      } catch (error) {
          console.error('Error:', error);
          return 'Error:'+ error
      }
}

export { CreateProfile, TranslateMT, PostEdit, Feedback, PostHumanEdition }