import React, { useState, useContext, useRef } from 'react';
import { MyContext } from '../../Context/translateContext';
import { CreateProfile} from './core/request';

function FileUpload() {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('')
  const [uploadStatus, setUploadStatus] = useState('Upload');
  const [uploadError, setUploadError] = useState('');
  const [showUploadButton, setShowUploadButton] = useState(false);

  const { 
    clientProfile, setClientProfile,
    langSource, langTarget,
    selectedFile, setSelectedFile
  } = useContext(MyContext);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // Extensiones permitidas y tamaño máximo de archivo (10MB en bytes)
  const allowedExtensions = ['tmx', 'csv', 'tsv'];
  const maxFileSize = 1 * 1024 * 1024; // 1 MB

  // Maneja la selección de archivos
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setFileName(file.name);
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size;

      if (!allowedExtensions.includes(fileExtension)) {
        setUploadError(`Only files with the following extensions are allowed: ${allowedExtensions.join(', ')}`);
        setSelectedFile(null);
        setShowUploadButton(false)
      } else if (fileSize > maxFileSize) {
        setUploadError(`The file is too large. The maximum size allowed is 1MB.`);
        setSelectedFile(null);
        setShowUploadButton(false)
      } else {
        setSelectedFile(file);
        setUploadStatus('Upload');
        setUploadError('');
        setShowUploadButton(true)
      }
    }
  };

  // Maneja la subida del archivo
  const handleFileUpload = async () => {
    setUploadError('')
    if (!selectedFile) {
      setUploadError('Por favor selecciona un archivo válido.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    let CP = clientProfile
    if(!clientProfile || clientProfile.sources !== langSource || clientProfile.target !== langTarget) {
      CP =  await CreateProfile(langSource, langTarget)
      setClientProfile({id: CP.id, sources: CP.sources, target: CP.target}) 
    }

    try {
      setUploadStatus('Indexing...');
      const response = await fetch('/upload/' + CP.id, {
        method: 'POST',
        body: formData,
      });
      console.log('Response:', response);

      if (response.status === 200) {
        console.log('Upload successful  ');
        setUploadStatus('PE file ready to use!!');
      } else {
        console.error('Upload failed:', response.statusText);
        setUploadStatus('Upload');
        setUploadError('Error uploading the file.');
      }
    } catch (error) {
      console.error('Error:'); 
      console.error('Error:', error);
      setUploadStatus('Upload');
      setUploadError('Error uploading the file.');
    }
  };

  return (
    <div className='py-4'>
        <p>
          <small>
            Upload Post Edition File (1MB limit): Accepted file types: .tmx, .csv, .tsv
          </small>
        </p>

        <div className="grid grid-cols-1 py-2 sm:grid-cols-2 md:grid-cols-3 gap-3">
            {/* Botón de seleccionar archivo */}
            <div className='col-span-1'>
              <button
                onClick={handleClick}
                className="w-full px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Select file:
              </button>

              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
              />
            </div>

            {/* Nombre del archivo seleccionado */}
            <div className='col-span-1'>
              {fileName && (
                <p className="text-gray-700">
                  <span className="font-semibold">{fileName}</span>
                </p>
              )}
            </div>

            {/* Botón de subir archivo */}
            <div className='col-span-1'>
              {showUploadButton && (
                <button
                  className="w-full px-4 py-2 font-semibold text-white bg-gray-800 rounded-lg hover:bg-gray-900"
                  onClick={handleFileUpload}
                  disabled={!selectedFile} // Deshabilitar botón si no hay archivo válido
                >
                            {uploadStatus && <span className="font-semibold">{uploadStatus}</span>}
                </button>
              )}
            </div>

        </div>
        <div className='pt-4'>
          {uploadError && <span className="text-red-500">{uploadError}</span>}
        </div>
    </div>

  );
}

export default FileUpload;

