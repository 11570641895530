import React, { useState, useContext, useEffect, useRef } from 'react';
import { MyContext } from '../../Context/translateContext';

export const Dropdown = ({ type, options, selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { 
    setOptionsTarget, setSelectedTarget, setSelectedService,
    setLangSource, setLangTarget,
    setText2, setText3,
    setShowAnswerPE,
    setShowFeedback
  } = useContext(MyContext);
  
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.text);
    setText2('');
    setText3('');
    setShowAnswerPE(false);
    setShowFeedback(false);

    if (type === 'source') {
      setOptionsTarget(option.target);
      setSelectedTarget(option.target[0].text);
      setLangSource(option.lang);
      setLangTarget(option.target[0].lang);
      setSelectedService(option.target[0].service);
    } else {
      setLangTarget(option.lang);
      setSelectedService(option.service);
    }

    setIsOpen(false);
  };

  // Manejar clic fuera del dropdown para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div 
        className="dropdown-header" 
        onClick={toggleDropdown}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedOption}
        <div className={`icon ${isOpen ? 'open' : ''}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul className="dropdown-menu" role="listbox" aria-labelledby="dropdown-header">
          {options.map((option, index) => (
            <li 
              key={index} 
              onClick={() => handleOptionClick(option)}
              role="option"
              aria-selected={selectedOption === option.text}
            >
              <small>{option.text}</small>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
