import React from 'react';

function Sidebar() {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white flex flex-col items-center">
      <div className="mt-10 px-4">
        <img
          src="./pangeanic_Logo.png"
          alt="Company Logo"
          // className="w-161 h-74"
        />
      </div>


      <div className="flex-1 flex flex-col text-sm font-normal text-pangeamt-bluedark mt-10 gap-y-5 mb-16">

          <div>
              <a href="mailto:info@pangeamt.com" title="clients@pangeanic.com" className="hover:text-eco-orangeDark">
                clients@pangeanic.com
              </a>
          </div> 
          <div>
              <a href="tel:+34963336333" title="+34 96 333 6 333 – Europe" className="hover:text-eco-orangeDark">
                +34 96 333 6 333 – Europe
              </a>
          </div> 
          <div>
              <a href="tel:+16174197145" title="(+1) 617 419 7145 – US" className="hover:text-eco-orangeDark">
                (+1) 617 419 7145 – US
              </a>
          </div>
      </div>

    </div>
  );
}

export default Sidebar;
